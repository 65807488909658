import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import * as types from '../types';
import { toggleReportLoading } from './report';
import { currentUser } from './general';

export const getSetting = () => async (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_SETTING,
      payload: res.data,
    });

    dispatch({
      type: types.SERVER_CONNECTION_HAS_FAILED,
      payload: false,
    });

    dispatch({
      type: types.SERVER_IS_DOWN,
      payload: false,
    });
  };

  const error = async (err) => {
    const errorDetails = err?.toJSON();

    if (errorDetails?.message === 'Network Error') {
      dispatch({
        type: types.SERVER_CONNECTION_HAS_FAILED,
        payload: true,
      });
    }

    if (errorDetails?.status === 500 || errorDetails?.status === 504) {
      dispatch({
        type: types.SERVER_IS_DOWN,
        payload: true,
      });
    }

    if (window.location.pathname.includes('login')) return;
    ApiErrorHandler(err);
  };

  await httpService.settings(response, error);
};

export const getSettingLogo = () => async (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_SETTING_LOGO,
      payload: res.data,
    });

    if (res?.status === 200) {
      const { company_logo, company_logo_dark } = res.data;

      const company_logos = {
        company_logo,
        company_logo_dark,
      };

      localStorage.setItem('company_logos', JSON.stringify(company_logos));
    }
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.settingsLogo(response, error);
};

export const updateSettings = (payload) => async (dispatch) => {
  const shouldReload = !!payload.theme;

  const respone = (res) => {
    // dispatch({
    //   type: types.UPDATE_SETTING,
    //   payload: res.data,
    // });
    dispatch(getSetting());
    dispatch(currentUser());
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.updateSettings(respone, error, JSON.stringify(payload));

  if (shouldReload) {
    window.location.reload();
  }
};

export const getViews = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_VIEWS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getViews(respone, error);
};

export const getDrillerView = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_DRILLER_VIEWS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getDrillerView(respone, error);
};

export const getSelectedDrillerView = (id) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_SELECTED_DRILLER_VIEW,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getViewDriller(respone, error, id);
};

export const getMudPumps = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_PUMPS_DATA,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getMudPumps(respone, error);
};

export const getView = (id) => (dispatch) => {
  dispatch(toggleReportLoading());

  const respone = (res) => {
    dispatch({
      type: types.GET_VIEW,
      payload: res.data,
    });
    dispatch(toggleReportLoading());
  };

  // eslint-disable-next-line consistent-return
  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    ApiErrorHandler(err);
    dispatch(toggleReportLoading());
  };
  httpService.getView(respone, error, id);
};

export const getViewDrillerAlert = (id) => (dispatch) => {
  dispatch(toggleReportLoading());

  const respone = (res) => {
    dispatch({
      type: types.GET_VIEW_DRILLER_ALERT,
      payload: res.data,
    });
    dispatch(toggleReportLoading());
  };

  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    ApiErrorHandler(err);
    dispatch(toggleReportLoading());
  };
  httpService.getViewDriller(respone, error, id);
};

export const setViewId = (id) => (dispatch) => {
  dispatch({
    type: types.SET_VIEW_ID,
    payload: id,
  });
};

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: loading,
  });
};

export const setFilteredData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_FILTERED_DATA,
    payload: data,
  });
};

export const fetchedFilteredData = (data) => (dispatch) => {
  dispatch({
    type: types.FETCHED_FILTERED_DATA,
    payload: data,
  });
};

export const fetchedReportdData = (data) => (dispatch) => {
  dispatch({
    type: types.FETCHED_REPORT_DATA,
    payload: data,
  });
};
export const setIsEditModeHalfGauge = (data) => (dispatch) => {
  dispatch({
    type: types.IS_EDIT_HALF_GAUGE,
    payload: data,
  });
};

export const getPermissionAccess = () => (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_PERMISSION_ACCESS,
      payload: res?.data?.value?.permissions_access || {},
    });
  };

  // eslint-disable-next-line consistent-return
  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    ApiErrorHandler(err);
    dispatch(toggleReportLoading());
  };
  httpService.getMetaKey(response, error, 'permissions_access');
};

export const getKeysPermissionsList = () => (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_PERMISSION_ACTIONS,
      payload: res?.data?.value?.permissions_actions || {},
    });
  };

  // eslint-disable-next-line consistent-return
  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    ApiErrorHandler(err);
    dispatch(toggleReportLoading());
  };
  httpService.getMetaKey(response, error, 'permissions_actions');
};
