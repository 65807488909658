/* eslint-disable no-self-assign */
/* eslint-disable object-shorthand */
import _ from 'lodash';
import { alwaysAllowed } from 'pages/ManageGroups';

// utils/permissionStore.js
let userRole = null;
let label = null;
let method = null;
let permissionsAccess = null;
let permissionsActions = null;

export const setPermissions = ({ role, access, actions }) => {
  userRole = role;
  permissionsAccess = access;
  permissionsActions = actions;
};

const checkPathPermisiion = () => {
  // ✅ Always allowed APIs
  if (alwaysAllowed.includes(label)) return true;

  // ✅ GET methods are always allowed
  if (method?.toLowerCase() === 'get') return true;

  // ✅ Match based on key, not label
  const item = _.find(permissionsActions, { key: label });

  const userPermission = permissionsAccess?.[userRole];

  return item && userPermission && userPermission.includes(item.id);
};



export const userHasAccess = (labelConf, methodConf) => {
  label = labelConf;
  method = methodConf;
  return checkPathPermisiion(label, method); // delegate the check
};

export const getPermissions = () => ({
  role: userRole,
  access: permissionsAccess,
  actions: permissionsActions,
});
