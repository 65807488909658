import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Table, Checkbox, Button, message, Space, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { httpService } from 'api/api';
import _ from 'lodash';
import { getKeysPermissionsList, getPermissionAccess } from 'store/actions/settings';
import { SearchOutlined } from '@ant-design/icons';
import ButtonWithIcon from 'components/shared/ButtonWithIcon';

export const alwaysAllowed = [
  'login',
  'initial',
  'settings',
  'settingsLogo',
  'currentUser',
  'getMetaKey',
  'setMetaKey',
  'updateUserInfo',
  'getHistoryId',
  'toggleAudibleAlarmUser',
  'getReportHistoryId',
  'getWellProfileData',
  'uploadCsv',
  'downloadCsv',
  'checkCsv',
  'exampleCsv',
  'toggleSnoozeTrackTrace',
  'getSetTallyLength',
  'toggleTallyStringSystem',
  'toggleSnoozeDrillerTrace',
  'toggleSnoozeTrace',
  'setupInsertData',
  'finishSetup',
  'addNewRock',
  'updateSettings',
  'updateRockEmoji',
  'deleteRockEmoji',
  'writeHistoryPlcScale',
  'readHistoryPlcScale',
  'bitsStatusUpdate',
  'bitsHeightUpdate',
  'setMetaKey',
  'updateStandPipe',
  'getScaled',
  'restoreStandToInsertedPipes',
  'renameStandToInsertedPipes',
  'trippingHours',
  'trippingHoursUpdate',
  'syncRemoteConnector',
  'createRemoteConnector',
  'deleteRemoteConnector',
  'addOperationalCode',
  'editOperationalCode',
  'deleteOperatedLogs',
  'addOperatedLogs',
  'deleteOperationalCode',
  'editOperatedLogs',
  'setRedisKeyToContext',
  'addBit',
  'editBit',
  'deleteBit',
];
// Memoized API key extraction to prevent unnecessary recalculations
const useApiList = () =>
  useMemo(() => {
    const newList = new Set(alwaysAllowed);
    return Object.entries(httpService)
      .filter(([key, fn]) => {
        if (typeof fn !== 'function' || !fn.label || fn.method === 'get') return false;
        if (newList.has(key)) return false;
        const fnStr = fn.toString().toLowerCase();
        return !fnStr.includes('.get(');
      })
      .map(([key, fn], index) => {
        const { label } = fn;

        // 🔍 Smart group by extracting base endpoint name
        const groupKey = key
          .replace(/^(add|edit|update|delete|remove|create|toggle|set|reset|write|read|get)/i, '') // Remove action prefix
          .replace(/By.*$/, '') // Optional: strip suffixes like ById, ByForm, etc
          .toLowerCase();

        return {
          id: index + 1,
          key,
          label,
          groupKey, // <-- used for grouping logic
        };
      });
  }, []);

export const ManageGroups = () => {
  const dispatch = useDispatch();
  const { permissions_access, permissions_actions } = useSelector((state) => state.settings);

  const apiList = useApiList();

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({}); // { role-apiKey: boolean }
  const [permissionsList, setPermissionsList] = useState({}); // { role: Set(id1, id2) }

  // New state for filters
  const [searchText, setSearchText] = useState('');
  const [filterApi, setFilterApi] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // Initialize data
  useEffect(() => {
    dispatch(getKeysPermissionsList());
    dispatch(getPermissionAccess());
    getExceptions();

    httpService.getRoles(
      (res) => setRoles(_.reject(res.data, { name: 'superadmin' }) || []),
      () => message.error('Failed to fetch roles')
    );
  }, [dispatch]);

  useEffect(() => {
    if (Object?.keys(permissions_actions)?.length <= 0 && apiList?.length > 0) {
      generateOneTimeActions(apiList);
    }
  }, [permissions_actions, apiList]);

  // Convert permissionsList to a more efficient Set-based structure
  useEffect(() => {
    if (permissions_access) {
      const optimizedPermissions = {};
      Object.entries(permissions_access).forEach(([role, ids]) => {
        optimizedPermissions[role] = new Set(ids);
      });
      setPermissionsList(optimizedPermissions);
    }
  }, [permissions_access]);

  // Initialize permissions state based on permissionsList
  useEffect(() => {
    const newPermissions = {};
    Object.entries(permissionsList).forEach(([role, idSet]) => {
      apiList.forEach(({ key, id }) => {
        newPermissions[`${role}-${key}`] = idSet.has(id);
      });
    });
    setPermissions(newPermissions);
  }, [permissionsList, apiList]);

  // Optimized permission toggle with useCallback
  const togglePermission = useCallback(
    (role, apiKey, id) => {
      const apiEntry = apiList.find((api) => api.key === apiKey);
      if (!apiEntry) return;

      const groupKey = apiEntry.groupKey;

      // 🎯 Only toggle permissions with the same groupKey
      const groupApis = apiList.filter((api) => api.groupKey === groupKey);

      const isCurrentlyEnabled = permissions[`${role}-${apiKey}`] || false;

      const updatedPermissions = { ...permissions };
      const updatedPermissionsList = { ...permissionsList };
      const rolePermissions = new Set(updatedPermissionsList[role] || []);

      // eslint-disable-next-line no-restricted-syntax
      for (const { key, id: relatedId } of groupApis) {
        const permissionKey = `${role}-${key}`;
        updatedPermissions[permissionKey] = !isCurrentlyEnabled;

        if (!isCurrentlyEnabled) {
          rolePermissions.add(relatedId);
        } else {
          rolePermissions.delete(relatedId);
        }
      }

      updatedPermissionsList[role] = rolePermissions;

      setPermissions(updatedPermissions);
      setPermissionsList(updatedPermissionsList);
    },
    [apiList, permissions, permissionsList]
  );

  const handleBulkUpdate = useCallback(
    (updateType) => {
      if (selectedRowKeys.length === 0) {
        message.warning('Please select roles to update');
        return;
      }

      const updatedPermissions = { ...permissionsList };
      selectedRowKeys.forEach((roleName) => {
        const currentRolePermissions = updatedPermissions[roleName] || new Set();

        apiList.forEach(({ id }) => {
          if (updateType === 'grant') {
            currentRolePermissions.add(id);
          } else if (updateType === 'revoke') {
            currentRolePermissions.delete(id);
          }
        });

        updatedPermissions[roleName] = currentRolePermissions;
      });

      setPermissionsList(updatedPermissions);
      // message.success(`Permissions ${updateType === 'grant' ? 'granted' : 'revoked'} for selected roles`);
    },
    [selectedRowKeys, permissionsList, apiList]
  );

  const filteredDataSource = useMemo(
    () =>
      roles
        .filter(
          (role) =>
            // Search filter
            role.name.toLowerCase().includes(searchText.toLowerCase()) &&
            // API key filter
            (!filterApi || permissions[`${role.name}-${filterApi}`] !== undefined)
        )
        .map((role) => {
          const row = { ...role, key: role.name };
          apiList.forEach(({ key }) => {
            row[key] = permissions[`${role.name}-${key}`] || false;
          });
          return row;
        }),
    [roles, permissions, apiList, searchText, filterApi]
  );

  const groupedApiColumns = useMemo(() => {
    const grouped = {};

    apiList.forEach((api) => {
      if (!grouped[api.groupKey]) {
        grouped[api.groupKey] = {
          groupKey: { key: api.groupKey, label: api.label },
          children: [],
        };
      }
      grouped[api.groupKey].children.push(api);
    });

    return Object.values(grouped);
  }, [apiList]);

  // Memoized columns configuration
  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: 'Role',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 140,
        filterDropdown: () => (
          <div className="p-2">
            <Input
              placeholder="Search role"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="block mb-2"
            />
          </div>
        ),
        filterIcon: () => <SearchOutlined style={{ color: searchText ? '#1890ff' : undefined }} />,
      },
    ];

    const groupedColumns = groupedApiColumns.map(({ groupKey, children }) => ({
      title: <div className="capitalize font-semibold">{groupKey?.key}</div>,
      children: children.map(({ key, label, id }) => ({
        title: <div className="whitespace-nowrap text-xs text-center px-1 ">{label}</div>,
        dataIndex: key,
        key,
        width: 120,
        render: (_, row) => (
          <div className="flex items-center justify-center h-full">
            <Checkbox
              checked={permissions[`${row.name}-${key}`] || false}
              onChange={() => togglePermission(row.name, key, id)}
            />
          </div>
        ),
      })),
    }));

    return [...baseColumns, ...groupedColumns];
  }, [groupedApiColumns, permissions, togglePermission, searchText]);

  // Row selection configuration
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  // Optimized data source with row virtualization
  // const dataSource = useMemo(() => {
  //   return roles.map((role) => {
  //     const row = { ...role, key: role.name };
  //     apiList.forEach(({ key }) => {
  //       row[key] = permissions[`${role.name}-${key}`] || false;
  //     });
  //     return row;
  //   });
  // }, [roles, permissions, apiList]);

  const handleApiCall = useCallback(
    (serviceFn, successMsg, errorMsg) => (payload) => {
      const response = (res) => message.success(successMsg);
      const error = (err) => message.error(errorMsg);
      serviceFn(response, error, ...payload);
    },
    []
  );

  const assignPermissionAccess = useCallback(
    handleApiCall(httpService.setMetaKey, 'Permissions saved', 'Failed to save permissions'),
    []
  );

  const handleBulkUpdateExceptions = (payload) => {
    const response = () => {};

    const error = () => {};

    httpService.setMetaKey(response, error, 'permissions_exception', { permissions_exception: payload });
  };

  const getExceptions = (useApiList) => {
    const response = (res) => {
      if (res.data.permissions_exception) {
        handleBulkUpdateExceptions(apiList);
      }
    };

    const error = () => {};

    httpService.getMetaKey(response, error, 'permissions_exception');
  };

  const generateOneTimeActions = (payload) => {
    const response = () => {};

    const error = () => {};

    httpService.setMetaKey(response, error, 'permissions_actions', { permissions_actions: payload });
  };

  // Convert Set back to array before saving
  const preparePermissionsForSave = useCallback(() => {
    const payload = {};
    Object.entries(permissionsList).forEach(([role, idSet]) => {
      payload[role] = Array.from(idSet);
    });
    return payload;
  }, [permissionsList]);

  return (
    <div className="p-4">
      {/* Bulk Update and Filter Controls */}
      <div className="flex justify-between items-center mb-4">
        <Space>
          <ButtonWithIcon
            type="primary"
            onClick={() => handleBulkUpdate('grant')}
            disabled={selectedRowKeys.length === 0}
            title="Grant All Selected"
          />
          <ButtonWithIcon
            type="primary"
            onClick={() => generateOneTimeActions(alwaysAllowed)}
            title="Grant All Selectedssssssssssss"
          />
          <ButtonWithIcon
            type="primary"
            onClick={() => handleBulkUpdateExceptions(alwaysAllowed)}
            title="update exception"
          />
          <ButtonWithIcon
            type="default"
            onClick={() => handleBulkUpdate('revoke')}
            disabled={selectedRowKeys.length === 0}
            title="Revoke All Selected"
          />
        </Space>

        <Space>
          <Select
            style={{ width: 200 }}
            placeholder="Filter by API Permission"
            allowClear
            onChange={(value) => setFilterApi(value)}
            options={apiList.map(({ key, label }) => ({
              value: key,
              label,
            }))}
          />
        </Space>
      </div>

      <h2 className="text-xl font-semibold mb-4">📋 Assign API Permissions to Roles</h2>

      <Table
        rowSelection={rowSelection}
        dataSource={filteredDataSource}
        columns={columns}
        size="small"
        pagination={false}
        bordered
        scroll={{ x: 'max-content' }}
      />

      <Button
        type="primary"
        className="mt-4"
        onClick={() =>
          assignPermissionAccess(['permissions_access', { permissions_access: preparePermissionsForSave() }])
        }
      >
        💾 Save Permissions
      </Button>
    </div>
  );
};
