import * as types from '../types';

const initialState = {
  settings: {},
  views: [],
  drillerViews: [],
  view: {},
  viewDriller: {},
  selectedDrillerView: {
    id: null,
    name: '',
    master_view: false,
    is_driller_menu: false,
    drillerchart_set: [],
    drillinggauge_set: [],
    drillingtrackes_set: [],
    tankconfig_set: [],
  },
  viewId: null,
  loading: true,
  filteredDate: [],
  fetchedFilteredData: [],
  reportData: [],
  serverConnectionHasError: false,
  serverIsDown: false,
  safetyToolsStatus: false,
  clientLogo: {},
  pumpsData: {},
  permissions_access: {},
  permissions_actions: {},
};

export default function settings(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_SETTING:
      return {
        ...state,
        settings: payload,
      };
    case types.GET_SETTING_LOGO:
      return {
        ...state,
        clientLogo: payload,
      };
    case types.UPDATE_SETTING:
      return {
        ...state,
        settings: payload,
      };
    case types.SERVER_CONNECTION_HAS_FAILED:
      return {
        ...state,
        serverConnectionHasError: payload || false,
      };
    case types.SERVER_IS_DOWN:
      return {
        ...state,
        serverIsDown: payload || false,
      };
    case types.GET_VIEWS:
      return {
        ...state,
        views: payload,
      };
    case types.GET_DRILLER_VIEWS:
      return {
        ...state,
        drillerViews: payload,
      };
    case types.GET_SELECTED_DRILLER_VIEW:
      return {
        ...state,
        selectedDrillerView: payload,
      };
    case types.GET_VIEW:
      return {
        ...state,
        view: payload,
      };
    case types.GET_PUMPS_DATA:
      return {
        ...state,
        pumpsData: payload,
      };
    case types.GET_VIEW_DRILLER_ALERT:
      return {
        ...state,
        viewDriller: payload,
      };
    case types.SET_VIEW_ID:
      return {
        ...state,
        viewId: payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case types.SET_FILTERED_DATA:
      return {
        ...state,
        filteredDate: payload,
      };
    case types.FETCHED_FILTERED_DATA:
      return {
        ...state,
        fetchedFilteredData: payload,
      };
    case types.FETCHED_REPORT_DATA:
      return {
        ...state,
        reportData: payload,
      };
    case types.IS_EDIT_HALF_GAUGE:
      return {
        ...state,
        isEditHalfGauge: payload,
      };
    case types.SET_SAFETY_TOOLS_STATUS:
      return {
        ...state,
        safetyToolsStatus: payload,
      };
    case types.GET_PERMISSION_ACCESS:
      return {
        ...state,
        permissions_access: payload,
      };
    case types.GET_PERMISSION_ACTIONS:
      return {
        ...state,
        permissions_actions: payload,
      };
    default:
      return state;
  }
}
