/* eslint-disable default-param-last */
// Import your libraries
import axios from 'axios';
import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';
import { isRemoteMode } from 'utils/ApiErrorHandler';
import { userHasAccess } from 'utils/PermissionStore';

// Check if user is logged in
const userToken = Cookies.get('userToken');
const userRole = Cookies.get('role');

// Restricted GET endpoints
const restrictedGetEndpoints = ['plc/read/wob/reference'];

// Function to check if the GET endpoint is restricted
const isGetEndpointRestricted = (url) => restrictedGetEndpoints.some((endpoint) => url.includes(endpoint));

// Restricted endpoints for non-GET methods
const restrictedEndpoints = [
  'project/create',
  'project/update',
  'project/add/sensor',
  'setup/update',
  'setup/delete',
  'plc/direct',
  'plc/calc/factor/motor',
  'plc/calc/factor/motor/get',
  'plc/reset/encoder',
  'plc/write/encoder',
  'plc/write/digital',
  'plc/scale/parameter',
  'plc/writeScale',
  'plc/save/sensor/calibration',
  'plc/writepaddle',
  'plc',
  'drilling/tools/reset/depth',
  'constants/reset',
  'plc/paddle',
  'plc/paddles',
  'setup/plc/connection/test',
  'plc/mudpump/save',
  'plc/reset/mp',
  'plc/mudpump',
  'plc/reset/reference',
  'project/attach/sensors',
  'project/dettach/sensors',
  'plc/set/wob/reference',
  'drilling/set/wob',
  'drilling/auto/tally/safety',
  'plc/redis',
  'drilling/cement/enabled',
  'drilling/tools/group/update/by/click',
  'constants/ref/active/standpipe',
  'drilling/cement/depth',
  'drilling/well/depth',
  // 'redis',
  // Driller-menu
  // 'drilling/trackes',
  // 'drilling/gauges',
  'drilling/set/volume',
  // 'drilling/set/tanks',
  // 'drilling/tank',
  // 'drilling/view/traces',
  'drilling/tools/upload',
  'drilling/case/upload',
  'drilling/tools',
  'drilling/case',
  // Main page
  // 'contexts/edit',
  // 'contexts/add',
  // 'contexts/remove',
  // 'contexts/update',
  // 'contexts/toggle',
  // 'contexts/tracks',
  'contexts/operation',
  'memos',
  // 'contexts/view',
  // Settings
  'setup/add/unit',
  'constants/unit',
  'setup/delete/unit',
  'constants/tripping',
  'constants/differential',
  'constants/active/tanks',
  'setup/delete',
  // 'setup/company/info',
  'constants/category/units',
  // 'constants/colors',
  'constants/wits',
  'redis',
  'redis/auto_depth_reference_weight',
  'services/tally/mode',
  'constants/wits',
];

// Function to check if the endpoint is restricted
const isEndpointRestricted = (url) => restrictedEndpoints.some((endpoint) => url.includes(endpoint));

// Function to check if the endpoint is allowed
const isEndpointAllowed = (url, db) => {
  const allowedEndpoints = ['/login', '/refresh', `history/${db}/base/dates`];
  return allowedEndpoints.some((endpoint) => url.includes(endpoint));
};

// Function to get device information
const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    os: result.os.name || 'Unknown OS',
    osVersion: result.os.version || 'Unknown OS Version',
    browser: result.browser.name || 'Unknown Browser',
    browserVersion: result.browser.version || 'Unknown Browser Version',
    device: result.device.type || 'Unknown Device',
    deviceModel: result.device.model || 'Unknown Device Model',
    deviceVendor: result.device.vendor || 'Unknown Vendor',
    cpu: result.cpu.architecture || 'Unknown CPU Architecture',
    engineName: result.engine.name || 'Unknown Engine',
    engineVersion: result.engine.version || 'Unknown Engine Version',
    userAgent: result.ua || 'Unknown User Agent',
  };
};

// Async function to gather device info and IP
const getAllDeviceInfo = async () => {
  const deviceInfo = getDeviceInfo();
  // const ipAddress = await getIPAddress();  // Uncomment if IP is required
  return {
    ...deviceInfo,
    // ipAddress,
  };
};

const addRequestInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const method = config.method.toLowerCase();
      const { url } = config;
      // Extract db value from the URL
      const dbMatch = url.match(/history\/(.*?)\//);
      const db = dbMatch ? dbMatch[1] : null;
      if (!userHasAccess(config.headers['X-API-LABEL'], method) && userRole !== 'superadmin') {
        const error = new Error(`Method ${method.toUpperCase()} is not allowed for the URL ${url}`);
        error.isCustom = true;
        error.response = {
          data: {
            status_code: 415,
            error: 'You do not have permission to perform this action.',
          },
          status: 415,
        };
        return Promise.reject(error);
      }

      if (
        isRemoteMode('FALSE_REMOTE_MODE') &&
        ((method !== 'get' && isEndpointRestricted(url)) || // Restrict non-GET methods
          (method === 'get' && isGetEndpointRestricted(url))) && // Restrict specific GET endpoints
        !isEndpointAllowed(url, db) // Check user access
      ) {
        const error = new Error(`Method ${method.toUpperCase()} is not allowed for the URL ${url}`);
        error.isCustom = true;
        error.response = {
          data: {
            status_code: 410,
            error: 'Live Mode: some requests/actions are restricted.',
          },
          status: 410,
        };
        return Promise.reject(error);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

// Basic Axios instance setup
const createAxiosInstance = (baseURL, contentType = 'application/json') =>
  axios.create({
    baseURL,
    timeout: 360000,
    headers: {
      'content-type': contentType,
      Authorization: userToken ? `Bearer ${userToken}` : '',
    },
  });

// Main Axios instances
const baseURL = process.env.REACT_APP_API_BASE_PRODUCTION;
export const instance = createAxiosInstance(baseURL);
export const instanceDownload = createAxiosInstance(baseURL, 'blob');
export const instanceSoftware = createAxiosInstance(baseURL);
export const instanceSoftwareUpload = createAxiosInstance(baseURL, 'multipart/form-data');

addRequestInterceptor(instance);
addRequestInterceptor(instanceDownload);
addRequestInterceptor(instanceSoftware);
addRequestInterceptor(instanceSoftwareUpload);

// Remote instance with specific headers
export const remoteSoftware = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PRODUCTION,
  timeout: 0,
  headers: {
    'content-type': 'application/json',
    Authorization: userToken ? `Bearer ${userToken}` : '',
    'REMOTE-X-TOKEN': '055ce16a62acd80fc1a793e554445e2d',
    'X-INTERNAL': 1,
  },
});

// Function to create Axios instance with device info in headers
const createAxiosInstanceWithInterceptors = async (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      if (process.env.NODE_ENV === 'production') {
        // Only apply in production
        const deviceInfo = await getAllDeviceInfo();
        config.headers['X-Device-OS'] = deviceInfo.os;
        config.headers['X-Device-OS-Version'] = deviceInfo.osVersion;
        config.headers['X-Device-Browser'] = deviceInfo.browser;
        config.headers['X-Device-Browser-Version'] = deviceInfo.browserVersion;
        config.headers['X-Device-Type'] = deviceInfo.device;
        config.headers['X-Device-Model'] = deviceInfo.deviceModel;
        config.headers['X-Device-Vendor'] = deviceInfo.deviceVendor;
        config.headers['X-Device-CPU'] = deviceInfo.cpu;
        config.headers['X-Browser-Engine-Name'] = deviceInfo.engineName;
        config.headers['X-Browser-Engine-Version'] = deviceInfo.engineVersion;
        config.headers['X-User-Agent'] = deviceInfo.userAgent;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// Apply interceptors conditionally
if (process.env.NODE_ENV === 'production') {
  createAxiosInstanceWithInterceptors(instance);
  createAxiosInstanceWithInterceptors(instanceDownload);
  createAxiosInstanceWithInterceptors(instanceSoftware);
  createAxiosInstanceWithInterceptors(instanceSoftwareUpload);
}

// Intercept the response and handle 401 error
instanceSoftware.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest) return Promise.reject(error);
    originalRequest._retry = true;
    if (error?.response?.data?.status_code === 401 && error?.response?.data?.error === 'Token is invalid or expired') {
      Cookies.remove('userToken');
      localStorage.removeItem('userToken');
      Cookies.remove('refresh');
      localStorage.removeItem('refresh');
    }
    // Check if it's a 401 error & not previously retried
    if (error?.response?.status === 401 && !originalRequest._retry) {
      const refreshToken = Cookies.get('refresh') || localStorage.getItem('refresh');
      if (!refreshToken || refreshToken === 'undefined') return Promise.reject(error);
      // Try to get a new token
      if (error?.response?.data?.detail === 'Token is invalid or expired') {
        Cookies.remove('userToken');
        Cookies.remove('refresh');
        localStorage.removeItem('refresh');
        localStorage.removeItem('userToken');
        Cookies.remove('is_active');
        Cookies.remove('username');
      }
      return instanceSoftware.post('/refresh', { refresh: refreshToken }).then((res) => {
        const { access } = res.data;
        Cookies.set('userToken', access);
        localStorage.setItem('userToken', access);
        // Modify the original request and retry
        // originalRequest.headers.Authorization = `Bearer ${access}`;
        return instanceSoftware(originalRequest);
        // return Promise.reject(error);
      });
    }
    return Promise.reject(error);
  }
);

// function define(fn, label, keyApi) {
//   const method = fn.toString().toLowerCase().includes('.get(') ? 'get' : 'post';
//   const wrapped = (...args) => {
//     // Inject label into the request config if it's an Axios call
//     // eslint-disable-next-line no-plusplus
//     for (let i = 0; i < args.length; i++) {
//       if (args[i] && typeof args[i] === 'object' && !Array.isArray(args[i])) {
//         // Don't override if header already has the label
//         if (args[i].headers && !args[i].headers['X-API-LABEL']) {
//           args[i].headers['X-API-LABEL'] = label;
//         }
//       }
//     }
//     return fn(...args, label, keyApi); // keep core behavior
//   };

//   Object.defineProperty(wrapped, 'label', { value: label, writable: false });
//   Object.defineProperty(wrapped, 'method', { value: method, writable: false });
//   return wrapped;
// }

// function define(fn, label) {
//   const method = fn.toString().toLowerCase().includes('.get(') ? 'get' : 'post';
//   let keyApi = '';
//   const wrapped = (...args) => {
//     console.log("...args",fn);
//     // Inject label into the request config if it's an Axios call
//     // eslint-disable-next-line no-plusplus
//     for (let i = 0; i < args.length; i++) {
//       if (args[i] && typeof args[i] === 'object' && !Array.isArray(args[i])) {
//         // Don't override if header already has the label
//         if (args[i].headers && !args[i].headers['X-API-LABEL']) {
//           args[i].headers['X-API-LABEL'] = label;
//           args[i].headers['X-PERMISSIONS-ACCESS'] = Object.entries(httpService).map(key => key[i]);
//         }
//       }
//     }
//     const keyApi = 'mahdi'
//     return fn(...args, label, keyApi); // keep core behavior
//   };

//   Object.defineProperty(wrapped, 'label', { value: label, writable: false });
//   Object.defineProperty(wrapped, 'method', { value: method, writable: false });
//   return wrapped;
// }

function define(fn, label) {
  const method = fn.toString().toLowerCase().includes('.get(') ? 'get' : 'post';

  const wrapped = (...args) => {
    const keyApi = wrapped.__keyName || ''; // <-- Injected later

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < args.length; i++) {
      if (args[i] && typeof args[i] === 'object' && !Array.isArray(args[i])) {
        if (!args[i].headers) {
          args[i].headers = {};
        }
        if (!args[i].headers['X-API-LABEL']) {
          args[i].headers['X-API-LABEL'] = keyApi;
        }
        if (!args[i].headers['X-PERMISSIONS-ACCESS']) {
          args[i].headers['X-PERMISSIONS-ACCESS'] = label;
        }
      }
    }

    return fn(...args, label, keyApi); // optional use
  };

  Object.defineProperty(wrapped, 'label', { value: label, writable: false });
  Object.defineProperty(wrapped, 'method', { value: method, writable: false });
  return wrapped;
}

export const httpService = {
  // auth
  login: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/login', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Login'
  ),
  initial: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('/contexts/initial', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Initial'
  ),
  settings: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('contexts/settings', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Settings'
  ),
  settingsLogo: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('contexts/settings?req=logo', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'SettingsLogo'
  ),
  currentUser: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('current_user', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'CurrentUser'
  ),
  updateUserInfo: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`edit/${id}/user`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'UpdateUserInfo'
  ),
  toggleAudibleAlarmUser: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .patch(`user/audio/alarms`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ToggleAudibleAlarmUser'
  ),
  resetPassword: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .put(`reset/password`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'profile > Reset Password'
  ),
  getRoles: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`user/roles`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetRoles'
  ),
  // ACCESS LEVELS -------------------------
  getUser: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('list_users', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetUser'
  ),
  addUser: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('add/user', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > groups > Users > AddUser'
  ),
  editUser: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`edit/${id}/user`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > groups > Users > EditUser'
  ),
  deleteUser: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`delete/${id}/user`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > groups > Users > DeleteUser'
  ),
  generatePassword: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post(`generate/password`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > groups > Users > GeneratePassword'
  ),
  // LOGS
  getLogs: define(
    (callback, catchError, page, qeuryFilter, label, keyApi) =>
      instanceSoftware
        .get(`/log_entry/?page=${page}&page_size=100&${qeuryFilter}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetLogs'
  ),
  getLogsUserAgent: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/log_entry/application/logs`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetLogsUserAgent'
  ),
  getLogsUserAgentWithCredential: define(
    (callback, catchError, credential, label, keyApi) =>
      instanceSoftware
        .get(`/log_entry/application/logs?app=${credential}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetLogsUserAgentWithCredential'
  ),
  getAppsLogs: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/log_entry/application/logs/apps`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAppsLogs'
  ),
  // data base DB --------------------------------
  getAllHistory: define(
    (callback, catchError, db, label, keyApi) =>
      instanceSoftware
        .get(`history/database/${db}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllHistory'
  ),
  getFilteredHistory: define(
    (callback, catchError, db, range, label, keyApi) =>
      instanceSoftware
        .get(`history/${db}/${range}/firstAndLast`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetFilteredHistory'
  ),
  getFilteredHistoryNew: define(
    (callback, catchError, db, range) =>
      instanceSoftware
        .get(`/history/${db}/based/datetime/firstAndLast?interval=${range}`)
        .then(callback)
        .catch(catchError),
    'GetFilteredHistoryNew'
  ),
  getFilteredDateHistory: define(
    (callback, catchError, db, data, label, keyApi) =>
      instanceSoftware
        .get(`history/${db}/dates/firstAndLast?start=${data[0]}&end=${data[1]}`)
        .then(callback)
        .catch(catchError),
    'GetFilteredDateHistory'
  ),
  getHistory: define(
    (callback, catchError, history_id, db, label, keyApi) =>
      instanceSoftware
        .get(`history/${history_id}/database/${db}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetHistory'
  ),
  getHistoryId: define(
    (callback, catchError, db, ids, label, keyApi) =>
      instanceSoftware
        .post(`history/${db}/base/dates`, ids, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetHistoryId'
  ),
  getReportHistoryId: define(
    (callback, catchError, db, ids, label, keyApi) =>
      instanceSoftware
        .post(`history/${db}/report/two/minutes`, ids, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetReportHistoryId'
  ),
  getHistorFirstLastID: define(
    (callback, catchError, db, label, keyApi) =>
      instanceSoftware
        .get(`history/${db}/id_range`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetHistorFirstLastID'
  ),
  // ---------------------------------------
  updateSettings: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch('contexts/settings/update', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'UpdateSettings'
  ),
  onSafetyAutoTally: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post('drilling/auto/tally/safety', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > system > safety AutoTally'
  ),
  getSafetyAutoTally: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/auto/tally/safety', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetSafetyAutoTally'
  ),
  getSetTallyLength: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post('drilling/auto/tally/safety', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetSetTallyLength'
  ),
  // data
  getWellProfileData: define(
    (callback, catchError, data, label, keyApi) =>
      instance
        .post('/', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetWellProfileData'
  ),
  // project
  updateProjectInfo: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`/project/update/${id}/info`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > projects > Update Project Info'
  ),
  createProject: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/project/create', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > projects > Create Project'
  ),
  deleteProject: define(
    (callback, catchError, projectId, label, keyApi) =>
      instanceSoftware
        .delete(`/project/${projectId}/destroy`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > projects > Delete Project'
  ),
  updateProjectFlag: define(
    (callback, catchError, projectId, data, label, keyApi) =>
      instanceSoftware
        .patch(`/project/update/${projectId}/flags`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > projects > Update Project Flag'
  ),
  addSensorToProject: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/project/attach/sensors', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > add Sensor Project'
  ),
  removeSensorToProject: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/project/dettach/sensors', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > Remove Sensor'
  ),
  updateSensor: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`setup/update/${id}/sensor`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > Update Sensor'
  ),
  projectAddSensor: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/project/add/sensor', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > Project AddSensor'
  ),
  // SETUP
  addSensorForsetup: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('/setup/add/sensor', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'initial process > step 2 > Add Sensor setup'
  ),
  uploadCsv: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftwareUpload
        .post('/setup/load/csv', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'UploadCsv'
  ),
  downloadCsv: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftwareUpload
        .get('/setup/download/csv/sensors', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DownloadCsv'
  ),
  checkCsv: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('setup/check/csv/exist', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'CheckCsv'
  ),
  exampleCsv: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('/setup/download/csv/example', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ExampleCsv'
  ),

  updateUnit: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`constants/unit/${id}/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > Update Unit'
  ),
  addUnit: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`setup/add/unit`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings >Add Unit'
  ),
  deleteUnit: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`constants/unit/${id}/delete`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > Delete Unit'
  ),
  deletSensorSetup: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`setup/delete/${id}/sensor`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'initial process > step 2 > Delete Sensor'
  ),
  toggleSnoozeTrackTrace: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/toggle/snooze/${id}/track`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ToggleSnoozeTrackTrace'
  ),
  toggleSnoozeTrace: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/toggle/snooze/${id}/trace`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ToggleSnoozeTrace'
  ),
  toggleSnoozeDrillerTrace: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`driller/toggle/snooze/${id}/trace`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ToggleSnoozeDrillerTrace'
  ),
  toggleTallyStringSystem: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`services/tally/mode`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ToggleTallyStringSystem'
  ),
  setupInsertData: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post(`setup/insert/data`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'SetupInsertData'
  ),
  finishSetup: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post(`setup/finish`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'FinishSetup'
  ),
  // view
  createView: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('contexts/add/view', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Create View'
  ),
  createViewDriller: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('drilling/views', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Create View Driller'
  ),
  getViewDriller: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .get(`drilling/views/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetViewDriller'
  ),
  updateView: define(
    (callback, catchError, viewId, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/view/${viewId}/edit`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Update View'
  ),
  updateViewDriller: define(
    (callback, catchError, viewId, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/views/${viewId}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Update View Driller'
  ),
  removeView: define(
    (callback, catchError, viewId, label, keyApi) =>
      instanceSoftware
        .delete(`contexts/remove/${viewId}/view`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Remove View'
  ),
  removeDrillerView: define(
    (callback, catchError, viewId, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/views/${viewId}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Remove Driller View'
  ),
  getViews: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('contexts/views', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetViews'
  ),
  getView: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .get(`contexts/view/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetView'
  ),
  getDrillerView: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/views/user`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetDrillerView'
  ),
  addTrace: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('contexts/add/trace', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > AddTrace (chart)'
  ),
  addTraceDriller: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('drilling/view/traces', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Add Trace Driller (chart) '
  ),
  removeTrace: define(
    (callback, catchError, traceId, label, keyApi) =>
      instanceSoftware
        .delete(`contexts/remove/${traceId}/trace`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Setup views > Remove Trace'
  ),

  updateTrace: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/edit/${id}/trace`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'charts > Update Trace'
  ),
  updateDrillerViewTrace: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/view/traces/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'charts > Update Driller View Trace'
  ),
  updateDrillerGauges: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/gauges/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Update Gauges'
  ),

  // deleteGauge: define(
  //   (callback, catchError, id, label, keyApi) =>
  //     instanceSoftware
  //       .delete(`/drilling/gauges/${id}`, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'Driller-menu > Delete Gauge'
  // ),
  addGaugesDriller: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/gauges`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Add Gauge'
  ),
  getGaugesDriller: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/drilling/gauges`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetGaugesDriller'
  ),

  addTrackTrace: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('contexts/add/track', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'main page > Add Track Trace'
  ),
  updateTrackTrace: define(
    (callback, catchError, trackId, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/update/${trackId}/track`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'main page > Update Track Trace'
  ),
  removeTrackTrace: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`contexts/tracks/${id}/delete`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'main page > Remove Track Trace'
  ),

  removeDrillerViewTrace: define(
    (callback, catchError, traceId, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/view/traces/${traceId}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Remove Trace'
  ),
  addTrackDriller: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/trackes`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Add Track Traces'
  ),
  updateTrackDriller: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/trackes/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Update Track Traces'
  ),
  removeTrackDriller: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/trackes/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Remove Track Traces'
  ),
  getChartData: define(
    (callback, catchError, chartId, label, keyApi) =>
      instanceSoftware
        .get(`contexts/chart/${chartId}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetChartData'
  ),
  getViewData: define(
    (callback, catchError, viewId, label, keyApi) =>
      instanceSoftware
        .get(`contexts/view/${viewId}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetViewData'
  ),
  getAllTraces: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/sensors`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllTraces'
  ),
  getInstalledTraces: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .get(`project/installed/${id}/sensors`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetInstalledTraces'
  ),
  getProjects: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`project/`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetProjects'
  ),
  // constants
  getSensors: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('constants/sensors', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetSensors'
  ),
  getTrackTraces: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`contexts/tracks`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetTrackTraces'
  ),

  getTrackDriller: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/trackes`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetTrackDriller'
  ),

  // * Tanks
  addTrackTanks: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/tanks/trackes`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'mud-system > Add Track Trace'
  ),
  getTrackTanks: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/tanks/trackes`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetTrackTanks'
  ),
  updateTrackTanks: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tanks/trackes/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'mud-system > Update Track Trace'
  ),
  removeTrackTanks: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/tanks/trackes/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'mud-system > Remove Track Trace'
  ),
  getAllUnits: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/units`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllUnits'
  ),

  getDrillerLogs: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/tools/log`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetDrillerLogs'
  ),
  getAllColors: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/colors`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllColors'
  ),
  // Memos
  getAllMemos: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .get(`memos/${data ? `?${data}` : ''}`)
        .then(callback)
        .catch(catchError),
    'GetAllMemos'
  ),
  getAllMemosTags: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`memos/tags`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllMemosTags'
  ),
  getFilteredMemos: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .get(`memos/tags/memos?tags=${data}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetFilteredMemos'
  ),
  updateMemo: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`memos/${id}/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'floating right Annotations menu > Update Annotations'
  ),
  createMemo: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`memos/create`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'floating right Annotations menu > Create Annotations'
  ),
  getAllEmojis: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('memos/emojis', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllEmojis'
  ),
  addNewRock: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`memos/emoji/create`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'AddNewRock'
  ),
  updateRockEmoji: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`memos/emoji/${id}/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'UpdateRockEmoji'
  ),
  deleteRockEmoji: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`memos/emoji/${id}/delete`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DeleteRockEmoji'
  ),
  // Company
  updateCompany: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftwareUpload
        .patch(`setup/company/info`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > projects > Update Company Info'
  ),
  // PLC
  readPlcParameter: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/readScale`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > ReadPlcParameter'
  ),
  readPlcEncoder: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/read/encoder`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > ReadPlcEncoder'
  ),
  readSingleParameter: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .get(`plc/direct/${sensor}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > ReadSingleParameter'
  ),
  onFactorMotor: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`plc/calc/factor/motor`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauge > Motor Factor'
  ),
  getFactorMotor: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .get(`plc/calc/factor/motor/get`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetFactorMotor'
  ),
  encoderPlcReset: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`plc/reset/encoder`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > Encoder Plc Reset'
  ),
  readPlcDigital: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/read/digital`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > Read Plc Digital sensor'
  ),
  writePlcEncoder: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/write/encoder`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > Write Plc Encoder'
  ),
  writePlcDigital: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/write/digital`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > Write Plc Digital sensor'
  ),
  getScaled: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/scale/parameter`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > GetScaled'
  ),
  writePlcScale: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/writeScale`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > Plc Scale sensor'
  ),
  writeHistoryPlcScale: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/save/sensor/calibration`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'WriteHistoryPlcScale'
  ),
  readHistoryPlcScale: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .get(`/constants/meta/${sensor}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ReadHistoryPlcScale'
  ),
  writePlcPaddle: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/writepaddle`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > scale > WritePlcPaddle'
  ),
  readPlcPaddle: define(
    (callback, catchError, num, label, keyApi) =>
      instanceSoftware
        .get(`plc/paddle/${num}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ReadPlcPaddle'
  ),
  readPlcPaddlesAll: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`plc/paddles`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'ReadPlcPaddlesAll'
  ),
  testPlcConnection: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`setup/plc/connection/test`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'initial > step one > TestPlcConnection'
  ),
  mudPumpUpdate: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`plc/mudpump/save`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > sensors > Mud-Pump Update -OR- Driller-menu > gauges > Mud-Pump Update'
  ),
  resetMudPump: define(
    (callback, catchError, sensor, label, keyApi) =>
      instanceSoftware
        .post(`plc/reset/mp`, sensor, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Reset Mud Pump'
  ),
  mudPumpRead: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .get(`plc/mudpump/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'MudPumpRead'
  ),
  getMudPumps: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`plc/mudpump`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetMudPumps'
  ),
  setWobreference: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`plc/reset/reference`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Set Wob Reference'
  ),
  getWobReference: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`plc/read/wob/reference`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetWobReference'
  ),
  setManualWobRef: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`plc/set/wob/reference`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Set Manual Wob Ref'
  ),
  getMotorStatus: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/get/DHM/active`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetMotorStatus'
  ),
  updateMotorStatus: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/set/DHM/active`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Update Motor Status'
  ),
  // Export
  // exportData: define(
  //   (callback, catchError, projectId, data, label, keyApi) =>
  //     instanceSoftware
  //       .post(`export/${projectId}/filter`, data, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'ExportData'
  // ),
  // downloadExportData: define(
  //   (callback, catchError, data, label, keyApi) =>
  //     instanceSoftware
  //       .post(`export/download`, data, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'DownloadExportData'
  // ),
  // downloadExportLasData: define(
  //   (callback, catchError, data, label, keyApi) =>
  //     instanceSoftware
  //       .post(`export/las/download`, data, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'DownloadExportLasData'
  // ),
  getUserSavedExportTraces: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`export/user/data`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export > GetUserSavedExportTraces'
  ),
  getUserExportHistories: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`export/user/configs`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetUserExportHistories'
  ),
  createNewHistoryConfig: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`export/user/configs`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export > export History Config '
  ),
  updateHistoryConfig: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`export/user/configs/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export > Update History export Config'
  ),
  deleteHistoryConfig: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`export/user/configs/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export > Delete History export Config'
  ),
  prepareExportFile: define(
    (callback, catchError, file_type, db_id, data, label, keyApi) =>
      instanceSoftware
        .post(`export/${file_type}/${db_id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export > Export File'
  ),
  getExportedFiles: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('export/list-files', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetExportedFiles'
  ),
  downloadExportedFile: define(
    (callback, catchError, file_name, label, keyApi) =>
      instanceSoftware
        .get(`export/download/${file_name}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DownloadExportedFile'
  ),
  // Drilling
  getAllBits: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/bits', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllBits'
  ),
  bitsStatus: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/status', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'BitsStatus'
  ),
  bitsStatusUpdate: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch('drilling/update/status', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Bits Status Update'
  ),
  bitsHeightUpdate: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`/drilling/bit/${id}/inserted`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'BitsHeightUpdate'
  ),
  addBit: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('drilling/bits', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'AddBit'
  ),
  editBit: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/bits/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'EditBit'
  ),
  deleteBit: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/bits/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DeleteBit'
  ),
  // uploadToolsFile: define(
  //   (callback, catchError, data, label, keyApi) =>
  //     instanceSoftware
  //       .post(`drilling/tools/upload`, data, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'UploadToolsFile'
  // ),
  // downloadToolsFile: define(
  //   (callback, catchError, label, keyApi) =>
  //     instanceDownload
  //       .post(`drilling/tools/download`, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'DownloadToolsFile'
  // ),
  updateTankPitTable: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/set/tanks`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Update Tank Pit -OR- mud-system > Update Tank Pit'
  ),
  getTankPitTable: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/get/tanks`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetTankPitTable'
  ),
  getDrillingTanks: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/tank`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetDrillingTanks'
  ),
  addDrillingTank: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/tank`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Add Tank'
  ),
  deleteDrillingTank: define(
    (callback, catchError, key, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/tank/${key}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Delete Tank'
  ),
  updateDrillingTanks: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tank/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Update Tank'
  ),
  updateDrillingTanksUnitBulk: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tank/unit/bulk/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Update Tank Unit'
  ),
  updateTankToTalAvtive: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/set/volume`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Tanks > Update Tank ToTal Active'
  ),
  resetDepth: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .post(`drilling/tools/reset/depth`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Reset Depth'
  ),
  setForceCalculationsWOB: define(
    (callback, catchError, flag, label, keyApi) =>
      instanceSoftware
        .post(`drilling/set/wob`, flag, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > gauges > Set Force Calculations WOB'
  ),
  // toggleAutoTally: define(
  //   (callback, catchError, flag, label, keyApi) =>
  //     instanceSoftware
  //       .post(`plc/drilling/active/bha`, flag, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'ToggleAutoTally'
  // ),
  getAutoDepth: define(
    (callback, catchError, key, label, keyApi) =>
      instanceSoftware
        .get(`/plc/redis/${key}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAutoDepth'
  ),
  updateRedisKey: define(
    (callback, catchError, key, data, label, keyApi) =>
      instanceSoftware
        .post(`/plc/redis/${key}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > force calculations for WOB/ROP'
  ),
  toggleCement: define(
    (callback, catchError, flag, label, keyApi) =>
      instanceSoftware
        .post(`drilling/cement/enabled`, flag, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Cementing Setting'
  ),
  getCementStatus: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/cement/enabled`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetCementStatus'
  ),
  getCementDepth: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/cement/depth`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetCementDepth'
  ),
  getWellDepth: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/well/depth`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetWellDepth'
  ),
  setWellDepth: define(
    (callback, catchError, depth, label, keyApi) =>
      instanceSoftware
        .post(`drilling/well/depth`, depth, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Manually amend measured depth'
  ),
  updateCementDepth: define(
    (callback, catchError, amount, label, keyApi) =>
      instanceSoftware
        .post(`drilling/cement/depth`, amount, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Cementing Setting > Update Cement Depth'
  ),
  // setForceCalculationsROP: define(
  //   (callback, catchError, flag, label, keyApi) =>
  //     instanceSoftware
  //       .post(`drilling/set/rop/force/calc`, flag, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'SetForceCalculationsROP'
  // ),
  getForceCalculationsROP: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`drilling/get/rop/force/status`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetForceCalculationsROP'
  ),
  resetParams: define(
    (callback, catchError, parameter, label, keyApi) =>
      instanceSoftware
        .post(`constants/reset/parameter`, parameter, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Reset plc Parameters -OR- system > Reset plc Parameters'
  ),
  resetInternalParams: define(
    (callback, catchError, address, label, keyApi) =>
      instanceSoftware
        .post(`constants/reset/${address}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'Driller-menu > Reset custom Parameters -OR- system > Reset custom Parameters'
  ),
  // Tools
  getAllCasing: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/casing', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllCasing'
  ),
  getTools: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/tools', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetTools'
  ),
  updateTools: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tools/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Update String Tools'
  ),
  updateToolsAsBulk: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch('drilling/tools/group/update/by/click', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > active/deactivate tools'
  ),
  deleteTools: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/tools/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Delete String Tools'
  ),
  updateSortsTools: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tools/sort/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Update Sorts String Tools'
  ),
  getToolsInserted: define(
    (callback, catchError, query) =>
      instanceSoftware
        .get(`drilling/tools/inserted?${query || ''}`)
        .then(callback)
        .catch(catchError),
    'GetToolsInserted'
  ),
  // toggleTools: define(
  //   (callback, catchError, id, label, keyApi) =>
  //     instanceSoftware
  //       .patch(`drilling/tools/${id}/toggle/usable`, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'settings > Drilling String & Casing > ToggleTools'
  // ),
  toggleToolsBulk: define(
    (callback, catchError, ids, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/tools/toggle/usable/bulk`, ids, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > toggle tools up to down / reverse'
  ),
  addCasing: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('drilling/casing', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Add Casing'
  ),
  editCase: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/casing/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Edit Casing'
  ),
  deleteCase: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/casing/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Delete Casing'
  ),
  getAlarmMeter: define(
    (callback, catchError, amount, label, keyApi) =>
      instanceSoftware
        .get(`/drilling/meter/alarm/depth`, amount, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAlarmMeter'
  ),
  updateAlarmMeter: define(
    (callback, catchError, amount, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/meter/alarm/depth`, amount, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Depth sampling alarm'
  ),
  toggleAlarmMeter: define(
    (callback, catchError, toggle, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/switch/alarm/depth`, toggle, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Toggle Depth sampling alarm'
  ),
  getAlarmMeterStatus: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/drilling/switch/alarm/depth`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAlarmMeterStatus'
  ),
  // Casing tools
  getAllCasingTools: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/case', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllCasingTools'
  ),
  getCasingToolsInserted: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get('drilling/case/inserted', {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetCasingToolsInserted'
  ),
  addCasingTool: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post('drilling/case', data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Add Casing'
  ),
  updateCasingTool: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/case/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Update Casing'
  ),
  deleteCasingTool: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`drilling/case/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Delete Casing'
  ),
  toggleCasingTools: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .patch(`drilling/case/${id}/toggle/usable`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Toggle Casing'
  ),
  // updateSortsCasingTools: define(
  //   (callback, catchError, data, label, keyApi) =>
  //     instanceSoftware
  //       .patch(`drilling/case/sort/update`, data, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'settings > Drilling String & Casing > Update Sorts Casing'
  // ),
  // toggleCasingToolsBulk: define(
  //   (callback, catchError, ids, label, keyApi) =>
  //     instanceSoftware
  //       .patch(`drilling/case/toggle/usable/bulk`, ids, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'ToggleCasingToolsBulk'
  // ),
  // Constants
  getMetaKey: define(
    (callback, catchError, key, label, keyApi) =>
      instanceSoftware
        .get(`constants/meta/${key}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetMetaKey'
  ),
  setMetaKey: define(
    (callback, catchError, key, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/meta/${key}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'SetMetaKey'
  ),
  getSignals: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/signals`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetSignals'
  ),
  updateStandPipe: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/tools/stand`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'UpdateStandPipe'
  ),
  restoreStandToInsertedPipes: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/tools/restore`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'RestoreStandToInsertedPipes'
  ),
  renameStandToInsertedPipes: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/tools/rename/stand`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'RenameStandToInsertedPipes'
  ),
  toggleStatusStandToInsertedPipes: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/drilling/tools/toggle/stand/status`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'driller-menu > table pipes > hover table stands > Lock/Unlock stand pipes'
  ),
  // counterPipeOne: define(
  //   (callback, catchError, counterNum, label, keyApi) =>
  //     instanceSoftware
  //       .post(`/constants/ref/active/standpipe/${counterNum}`, {
  //         headers: { 'X-API-LABEL': label },
  //       })
  //       .then(callback)
  //       .catch(catchError),
  //   'CounterPipeOne'
  // ),
  getAllUnitsCategory: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/category/units`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllUnitsCategory'
  ),
  addCategory: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/category/units`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > Units > Add Category Units'
  ),
  updateCategory: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`constants/category/units/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > Units > Update Category Units'
  ),
  deleteCategory: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`constants/category/units/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > Units > Delete Category Units'
  ),
  // System settings
  getChartLimitNumber: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/setting/chart-number`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetChartLimitNumber'
  ),
  updateChartLimitNumber: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`constants/setting/chart-number/update`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > System >  UpdateChartLimitNumber'
  ),
  getPdfResolution: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/read/frequency/time`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetPdfResolution'
  ),
  updatePdfResolution: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/update/frequency/time`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'export reports > Select PDF resolution'
  ),
  // System settings
  getAllThreads: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/threads`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetAllThreads'
  ),
  addThread: define(
    (callback, catchError, payload, label, keyApi) =>
      instanceSoftware
        .post(`constants/threads`, payload, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Add Thread'
  ),
  editThread: define(
    (callback, catchError, id, payload, label, keyApi) =>
      instanceSoftware
        .patch(`constants/threads/${id}`, payload, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Edit Thread'
  ),
  deleteThread: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`constants/threads/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Delete Thread'
  ),
  addToolByForm: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`drilling/tools`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > Drilling String & Casing > Add Tool'
  ),
  getRedisKeys: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .get(`redis`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetRedisKeys'
  ),
  getRedisKey: define(
    (callback, catchError, key, label, keyApi) =>
      instanceSoftware
        .get(`plc/redis/${key}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetRedisKey'
  ),
  getRedisKeyFromContext: define(
    (callback, catchError, key, label, keyApi) =>
      instanceSoftware
        .get(`contexts/redis/read/${key}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetRedisKeyFromContext'
  ),
  setRedisKeyToContext: define(
    (callback, catchError, key, data, label, keyApi) =>
      instanceSoftware
        .post(`contexts/redis/read/${key}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'SetRedisKeyToContext'
  ),
  // color
  addColor: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/colors`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > system > Add Color'
  ),
  updateColor: define(
    (callback, catchError, id, data, label, keyApi) =>
      instanceSoftware
        .patch(`constants/colors/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > system > Update Color'
  ),
  deleteColor: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`constants/colors/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > settings > system > Delete Color'
  ),
  trippingHoursUpdate: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`constants/tripping/hours`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'TrippingHoursUpdate'
  ),
  trippingHours: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/get/tripping/hours`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'TrippingHours'
  ),
  updateDifferentialPressure: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`constants/differential/pressure`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Set Differential Pressure Reference'
  ),
  differentialPressure: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/get/differential/pressure`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > Set Differential Pressure Reference'
  ),
  toggleGainLossActivation: define(
    (callback, catchError, flag, label, keyApi) =>
      instanceSoftware
        .post(`constants/active/tanks/${flag}/running`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'system > activate Gain Loss in Tanks'
  ),
  isActiveGainLoss: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`constants/active/tanks/running`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'IsActiveGainLoss'
  ),
  // WITS
  getWits: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/constants/wits`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetWits'
  ),
  updateWits: define(
    (callback, catchError, data, id, label, keyApi) =>
      instanceSoftware
        .patch(`/constants/wits/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > wits > Update Wits'
  ),
  addWits: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/constants/wits`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > wits > Add Wits'
  ),
  deleteWits: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`/constants/wits/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'settings > wits > Delete Wits'
  ),
  // CONNECTORS KAFKA
  getRemoteConnector: define(
    (callback, catchError, headers = {}, label, keyApi) =>
      instanceSoftware
        .get(
          '/remote/connectors',
          { headers },
          {
            headers: { 'X-API-LABEL': label },
          }
        )
        .then(callback)
        .catch(catchError),
    'GetRemoteConnector'
  ),
  readRemoteConnector: define(
    (callback, catchError, connectors, headers = {}, label, keyApi) =>
      instanceSoftware
        .get(
          `/remote/status/${connectors}`,
          { headers },
          {
            headers: { 'X-API-LABEL': label },
          }
        )
        .then(callback)
        .catch(catchError),
    'ReadRemoteConnector'
  ),
  syncRemoteConnector: define(
    (callback, catchError, headers = {}, label, keyApi) =>
      instanceSoftware
        .post(
          '/remote/connectors',
          null,
          { headers },
          {
            headers: { 'X-API-LABEL': label },
          }
        )
        .then(callback)
        .catch(catchError),
    'SyncRemoteConnector'
  ),
  createRemoteConnector: define(
    (callback, catchError, payload, headers = {}, label, keyApi) =>
      remoteSoftware
        .post(
          '/remote/connectors',
          payload,
          { headers },
          {
            headers: { 'X-API-LABEL': label },
          }
        )
        .then(callback)
        .catch(catchError),
    'CreateRemoteConnector'
  ),
  deleteRemoteConnector: define(
    (callback, catchError, name, headers = {}, label, keyApi) =>
      remoteSoftware
        .delete(
          `/remote/connectors?name=${name}`,
          { headers },
          {
            headers: { 'X-API-LABEL': label },
          }
        )
        .then(callback)
        .catch(catchError),
    'DeleteRemoteConnector'
  ),
  // OPERATIONAL CODE
  getOperationalCode: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`contexts/operational/code`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetOperationalCode'
  ),
  addOperationalCode: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`contexts/operational/code`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'AddOperationalCode'
  ),
  editOperationalCode: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .patch(`contexts/operational/code/${data.id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'EditOperationalCode'
  ),
  deleteOperationalCode: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`contexts/operational/code/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DeleteOperationalCode'
  ),
  operatedLogs: define(
    (callback, catchError, label, keyApi) =>
      instanceSoftware
        .get(`/contexts/operation/action`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'OperatedLogs'
  ),
  getOperatedLog: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .get(`/contexts/operation/action/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'GetOperatedLog'
  ),
  deleteOperatedLogs: define(
    (callback, catchError, id, label, keyApi) =>
      instanceSoftware
        .delete(`/contexts/operation/action/${id}`, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'DeleteOperatedLogs'
  ),
  addOperatedLogs: define(
    (callback, catchError, data, label, keyApi) =>
      instanceSoftware
        .post(`/contexts/operation/action`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'AddOperatedLogs'
  ),
  editOperatedLogs: define(
    (callback, catchError, data, id, label, keyApi) =>
      instanceSoftware
        .patch(`/contexts/operation/action/${id}`, data, {
          headers: { 'X-API-LABEL': keyApi, 'X-PERMISSIONS-ACCESS': label },
        })
        .then(callback)
        .catch(catchError),
    'EditOperatedLogs'
  ),
};

// AFTER httpService is created
Object.entries(httpService).forEach(([key, fn]) => {
  if (typeof fn === 'function') {
    fn.__keyName = key; // This is the crucial part
  }
});
